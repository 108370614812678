import { template as template_f2f13c38b7ae489f940720f9518019c7 } from "@ember/template-compiler";
const FKLabel = template_f2f13c38b7ae489f940720f9518019c7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
